constant-translate {
  position: relative;
}
constant-translate-controls-wrapper {
  display: none;
  position: absolute;
  white-space: nowrap;
  padding-top: 5px;
  opacity: 0;
  z-index: 9999;
  line-height: 13px;
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
constant-translate-controls {
  font-size: 0;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #555e67;
  padding: 7px 10px 4px;
  border-radius: 5px;
  display: block;
  cursor: default;
  font-weight: normal;
  text-shadow: none;
}
constant-translate-controls constant-translate-control {
  color: #555e67;
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  cursor: pointer;
}
constant-translate-controls constant-translate-control:not(:first-child) {
  margin-left: 10px;
}
html[dir="rtl"] constant-translate-controls constant-translate-control:first-child {
  margin-right: 0;
  margin-left: 10px;
}
.constant-in-search {
  -webkit-animation: 1s linear 0s infinite normal none running blinking_red;
  -moz-animation: 1s linear 0s infinite normal none running blinking_red;
  -o-animation: 1s linear 0s infinite normal none running blinking_red;
  -ms-animation: 1s linear 0s infinite normal none running blinking_red;
  animation: 1s linear 0s infinite normal none running blinking_red;
}

@-webkit-keyframes blinking_red { 0% {background:transparent;color:#566283;} 50% {background:red;color:#fff;} 100% {background:transparent;color:#566283;} }
@-moz-keyframes blinking_red { 0% {background:transparent;color:#566283;} 50% {background:red;color:#fff;} 100% {background:transparent;color:#566283;} }
@-ms-keyframes blinking_red { 0% {background:transparent;color:#566283;} 50% {background:red;color:#fff;} 100% {background:transparent;color:#566283;} }
@-o-keyframes blinking_red { 0% {background:transparent;color:#566283;} 50% {background:red;color:#fff;} 100% {background:transparent;color:#566283;} }
@keyframes blinking_red { 0% {background:transparent;color:#566283;} 50% {background:red;color:#fff;} 100% {background:transparent;color:#566283;} }